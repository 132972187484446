import React from 'react'
import { Translation, useTranslation } from 'react-i18next'
import moment from 'moment'

import { COLORS } from 'COLORS'
import { parseCurrency } from 'helpers/currency'
import { unitSalesFormatter } from 'helpers/market_insights/share_and_trends'
import { SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/market_insights/share_and_trends'
import { EmptyCell } from 'ui_elements/TableV2/TableComponents/SharedCells/EmptyCell'
import { DimensionsCell } from 'ui_elements/TableV2/TableComponents/SharedCells/DimensionsCell'
import { DateCell } from 'ui_elements/TableV2/TableComponents/SharedCells/DateCell'
import { QuestionTooltip } from 'ui_elements/QuestionTooltip/QuestionTooltip'
import {
  BRANDS_TABLE_TOOLTIPS,
  CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS
} from 'constants/market_insights/brands_table_tooltips'
import {
  BuyboxWinPercentageRowTooltip,
  RevenueHeaderTooltip,
  RevenueSubheaderTooltip,
  UnitSalesHeaderTooltip,
  UnitSalesSubheaderTooltip,
  LinkTooltip
} from 'constants/market_insights/tooltips'
import { CHART_LABEL_NAMES } from './display_names'
import { SubRowCellTitleCell } from '../../components/market_insights/share_and_trends/SubRowCellTitleCell/SubRowCellTitleCell'
import { ProductTrendCell } from '../../components/market_insights/share_and_trends/ProductTrendCell/ProductTrendCell'
import { PriceCell } from '../../components/market_insights/share_and_trends/PriceCell/PriceCell'
import { NumberCell } from '../../components/market_insights/share_and_trends/NumberCell/NumberCell'
import { RatingCell } from '../../components/market_insights/share_and_trends/RatingCell/RatingCell'
import { StringCell } from '../../components/market_insights/share_and_trends/StringCell/StringCell'
import { EmptyChildCell } from '../../components/market_insights/share_and_trends/EmptyChildCell/EmptyChildCell'
import { ProductWeightCell } from '../../components/market_insights/share_and_trends/ProductWeightCell/ProductWeightCell'
import { CategoryCell } from '../../components/market_insights/share_and_trends/CategoryCell/CategoryCell'
import { SellerShareCell } from '../../components/market_insights/share_and_trends/SellerShareCell/SellerShareCell'
import { ExcelExportsDownloadCell } from '../../components/market_insights/share_and_trends/ExcelExportsDownloadCell/ExcelExportsDownloadCell'
import { ExcelExportsTextCell } from '../../components/market_insights/share_and_trends/ExcelExportsTextCell/ExcelExportsTextCell'

export const SHARE_AND_TRENDS_REQUEST_TIMEOUT = 120000

export const SEGMENT_TRENDS_CHART_LINES = (
  t,
  isUnitSalesRoundingEnabled = false
) => [
  {
    accessor: 'brands',
    label: CHART_LABEL_NAMES(t).brands,
    type: 'linear',
    isActive: false,
    valueFormatter: value => value.toLocaleString(),
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.CheckBox.Brands
  },
  {
    accessor: 'asins',
    label: CHART_LABEL_NAMES(t).asins,
    type: 'linear',
    isActive: false,
    valueFormatter: value => value.toLocaleString(),
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.CheckBox.Asins
  },
  {
    accessor: 'avgReviews',
    label: CHART_LABEL_NAMES(t).avgReviews,
    type: 'linear',
    isActive: false,
    valueFormatter: value => value.toLocaleString(),
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.CheckBox.AvgReviews
  },
  {
    accessor: 'avgPrice',
    label: CHART_LABEL_NAMES(t).avgPrice,
    tooltipContent: t(
      'market_insights:ShareAndTrends.lineChartGraph.tooltip.medianPrice',
      'The Median Price is calculated based on the buy box price for the selected time period and excludes any coupons or Subscribe & Save pricing.'
    ),
    type: 'linear',
    isActive: false,
    valueFormatter: (value, currencyCode) => parseCurrency(value, currencyCode),
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.CheckBox.MedianPrice
  },
  {
    accessor: 'unitSales',
    label: CHART_LABEL_NAMES(t).unitSales,
    type: 'linear',
    isActive: false,
    valueFormatter: value =>
      unitSalesFormatter(value, isUnitSalesRoundingEnabled),
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.CheckBox.UnitSales
  },
  {
    accessor: 'revenue',
    label: CHART_LABEL_NAMES(t).revenue,
    tooltipContent: t(
      'market_insights:ShareAndTrends.lineChartGraph.tooltip.revenue',
      'Revenue is calculated daily using estimated unit sales and the current buy box price. It is a combination of all sales, both 1P and 3P.'
    ),
    type: 'linear',
    isActive: false,
    valueFormatter: (value, currencyCode) =>
      parseCurrency(value, currencyCode, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }),
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.CheckBox.Revenue
  },
  {
    accessor: 'revenue1p',
    label: CHART_LABEL_NAMES(t).revenue1p,
    type: 'linear',
    isActive: false,
    valueFormatter: (value, currencyCode) =>
      parseCurrency(value, currencyCode, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }),
    yAxis: 'revenue',
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.CheckBox.Revenue1P
  },
  {
    accessor: 'revenue3p',
    label: CHART_LABEL_NAMES(t).revenue3p,
    type: 'linear',
    isActive: false,
    valueFormatter: (value, currencyCode) =>
      parseCurrency(value, currencyCode, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }),
    yAxis: 'revenue',
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.CheckBox.Revenue3P
  }
]

export const BRAND_TRENDS_OPTIONS = (t, isUnitSalesRoundingEnabled = false) => [
  {
    value: 'asins',
    label: CHART_LABEL_NAMES(t).asins,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.Radio.Asins,
    valueFormatter: value => value.toLocaleString()
  },
  {
    value: 'avgReviews',
    label: CHART_LABEL_NAMES(t).avgReviews,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.Radio.AvgReviews,
    valueFormatter: value => value.toLocaleString()
  },
  {
    value: 'avgPrice',
    label: CHART_LABEL_NAMES(t).avgPrice,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.Radio.MedianPrice,
    valueFormatter: (value, currencyCode) => parseCurrency(value, currencyCode)
  },
  {
    value: 'unitSales',
    label: CHART_LABEL_NAMES(t).unitSales,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.Radio.UnitSales,
    valueFormatter: value =>
      unitSalesFormatter(value, isUnitSalesRoundingEnabled)
  },
  {
    value: 'revenue',
    label: CHART_LABEL_NAMES(t).revenue,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.Radio.Revenue,
    valueFormatter: (value, currencyCode) => parseCurrency(value, currencyCode)
  },
  {
    value: 'revenue1p',
    label: CHART_LABEL_NAMES(t).revenue1p,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.Radio.Revenue1P,
    valueFormatter: (value, currencyCode) => parseCurrency(value, currencyCode)
  },
  {
    value: 'revenue3p',
    label: CHART_LABEL_NAMES(t).revenue3p,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.Radio.Revenue3P,
    valueFormatter: (value, currencyCode) => parseCurrency(value, currencyCode)
  }
]

const productTrendOrEmptyChildCell = (
  isSharedBsr,
  sharedBsrParentAsin,
  cellProps,
  cellValueType
) => {
  const isChild = isSharedBsr && sharedBsrParentAsin
  return isChild ? EmptyChildCell(cellValueType) : ProductTrendCell(cellProps)
}

export const BRANDS_TRENDS_TABLE_ADDITIONAL_COLUMNS = (
  currencyCode,
  usingAdminMegaQueries,
  isAdminOrImpersonating
) => [
  {
    index: 8,
    position: 8,
    accessor: 'avgPrice',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.avgPrice',
            'Avg. Price'
          )
        }
      </Translation>
    ),
    subHeader: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.lowestToHighest',
            'Lowest - Highest'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.avgPrice,
    Cell: ({
      row: {
        depth,
        original: { price: avg, priceMax: max, priceMin: min }
      }
    }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !avg
        ? EmptyCell({ tooltip })
        : PriceCell({
            avg,
            max,
            min,
            currencyCode,
            usingAdminMegaQueries,
            isAdminOrImpersonating
          })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.price,
    width: 200,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.AvgPrice,
    defaultSelection: false,
    type: 'number'
  },
  {
    index: 9,
    position: 9,
    accessor: 'reviews',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.reviews',
            'Reviews'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.reviews,
    Cell: ({
      row: {
        depth,
        original: { reviews: value }
      }
    }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !value
        ? EmptyCell({ tooltip })
        : NumberCell({ value })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.numReviews,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Reviews,
    defaultSelection: false,
    type: 'number'
  },
  {
    index: 10,
    position: 10,
    accessor: 'starRating',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.rating',
            'Rating'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.starRating,
    Cell: ({
      row: {
        depth,
        original: { starRating }
      }
    }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !starRating
        ? EmptyCell({ tooltip })
        : RatingCell({ rating: parseFloat(starRating) })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.rating,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Rating,
    defaultSelection: false,
    type: 'number'
  },
  {
    index: 11,
    position: 11,
    accessor: 'categories',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.category',
            'Category'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.category,
    Cell: ({
      row: {
        depth,
        original: { categories }
      }
    }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !categories
        ? EmptyCell({ tooltip, alignment: 'left' })
        : CategoryCell({ categories })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.category,
    width: 200,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Category,
    defaultSelection: false
  },
  {
    index: 12,
    position: 12,
    accessor: 'subCategories',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.subCategories',
            'Sub Categories'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.subCategory,
    Cell: ({
      row: {
        depth,
        original: { subCategories: categories }
      }
    }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !categories
        ? EmptyCell({ tooltip, alignment: 'left' })
        : CategoryCell({ categories })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.subCategory,
    width: 200,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.SubCategories,
    defaultSelection: false
  },
  // {
  //   index: 13,
  //   position: 13,
  //   accessor: 'buyBoxOwner',
  //   Header: (
  //     <Translation ns="market_insights">
  //       {t =>
  //         t(
  //           'market_insights:ShareAndTrends.brandTrendsTable.labels.buyBoxOwner',
  //           'Buy Box Owner'
  //         )
  //       }
  //     </Translation>
  //   ),
  //   Cell: ({
  //     row: {
  //       depth,
  //       original: { buyBoxOwner: value }
  //     }
  //   }) => {
  //     const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
  //     return depth === 0 || !value
  //       ? EmptyCell({ tooltip })
  //       : StringCell({ value })
  //   },
  //   headerTooltip: BRANDS_TABLE_TOOLTIPS.buyBoxOwner,
  //   sortable: false,
  //   dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.BuyBoxOwner,
  //   defaultSelection: true
  // },
  {
    index: 14,
    position: 14,
    accessor: 'newOfferCount',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.sellers',
            'Sellers'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.numSellers,
    Cell: ({
      row: {
        depth,
        original: { newOfferCount: value }
      }
    }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !value
        ? EmptyCell({ tooltip })
        : NumberCell({ value })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.numSellers,
    sortable: false,
    width: 100,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Sellers,
    defaultSelection: false,
    type: 'number'
  },
  {
    index: 15,
    position: 15,
    accessor: 'sellerTypes',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.sellerTypes',
            'Seller Types'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.sellerType,
    Cell: ({
      row: {
        depth,
        original: { sellerTypes }
      }
    }) => {
      const value =
        sellerTypes && sellerTypes.length > 0 ? sellerTypes.join(', ') : ''
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !value
        ? EmptyCell({ tooltip, alignment: 'left' })
        : StringCell({ value, alignment: 'left' })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.sellerType,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.SellerTypes,
    defaultSelection: false
  },
  {
    index: 16,
    position: 16,
    accessor: 'length',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.dimensions',
            'Dimensions (LxWxH)'
          )
        }
      </Translation>
    ),
    subHeader: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.tier',
            'Tier'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.dimensions,
    Cell: ({ row: { depth, original } }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !original?.height
        ? EmptyCell({ tooltip })
        : DimensionsCell({ item: original })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.dimensions,
    width: 200,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Dimensions,
    defaultSelection: false,
    type: 'number'
  },
  {
    index: 17,
    position: 17,
    accessor: 'weight',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.weight',
            'Weight'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.weight,
    Cell: ({
      row: {
        depth,
        original: { weight, weightUnit }
      }
    }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !weight
        ? EmptyCell({ tooltip })
        : ProductWeightCell({ weight, weightUnit })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.weight,
    width: 120,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Weight,
    defaultSelection: false,
    type: 'number'
  },
  {
    index: 18,
    position: 18,
    accessor: 'salesRank',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.avgBsr',
            'Avg. BSR'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.bsr,
    Cell: ({
      row: {
        depth,
        original: { salesRank }
      }
    }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      const value = salesRank ? Math.round(salesRank) : null
      return depth === 0 || !value
        ? EmptyCell({ tooltip })
        : NumberCell({ value })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.bsr,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.SalesRank,
    defaultSelection: false,
    type: 'number'
  },
  {
    index: 19,
    position: 19,
    accessor: 'listedAt',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.listedAt',
            'Date First Available'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.dateFirstAvailable,
    Cell: ({
      row: {
        depth,
        original: { listedAt }
      }
    }) => {
      const tooltip = depth === 0 ? BRANDS_TABLE_TOOLTIPS.expandRow : null
      return depth === 0 || !listedAt
        ? EmptyCell({ tooltip })
        : DateCell({ date: listedAt, format: 'MM/DD/YYYY' })
    },
    headerTooltip: BRANDS_TABLE_TOOLTIPS.dateFirstAvailable,
    sortable: false,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.DateFirstAvailable,
    defaultSelection: false,
    type: 'number'
  }
]

export const BRANDS_TRENDS_TABLE_ALL_COLUMNS = (
  onAsinClick,
  currencyCode = null,
  additionalColumns = false,
  usingAdminMegaQueries = false,
  isAdminOrImpersonating = false,
  unitSalesDecimalPoints = 1,
  hasTrendData = false
) => [
  {
    index: 0,
    position: 0,
    accessor: 'name',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.brand',
            'Brand'
          )
        }
      </Translation>
    ),
    subHeader: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.asin',
            'ASIN'
          )
        }
      </Translation>
    ),
    Cell: ({ row }) => SubRowCellTitleCell({ row, onAsinClick }),
    width: 437,
    sortable: true,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Brand,
    defaultSelection: true
  },
  {
    index: 1,
    position: 1,
    accessor: 'revenue',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.revenue',
            'Revenue'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.revenue,
    Cell: ({
      row: {
        original: {
          revenue: value,
          revenueTrend: trend,
          isSharedBsr,
          sharedBsrParentAsin,
          asin
        }
      }
    }) => {
      const cellProps = {
        value,
        trend,
        currencyCode,
        isAsin: !!asin,
        hasTrendData
      }
      return productTrendOrEmptyChildCell(
        isSharedBsr,
        sharedBsrParentAsin,
        cellProps,
        'revenue'
      )
    },
    sortable: true,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Revenue,
    defaultSelection: true,
    type: 'number'
  },
  {
    index: 2,
    position: 2,
    accessor: 'revenue1p',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.revenue1p',
            '1P Revenue'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.revenue1p,
    Cell: ({
      row: {
        original: {
          revenue1p: value,
          revenue1pTrend: trend,
          isSharedBsr,
          sharedBsrParentAsin,
          asin
        }
      }
    }) => {
      const cellProps = {
        value,
        trend,
        currencyCode,
        isAsin: !!asin,
        hasTrendData
      }
      return productTrendOrEmptyChildCell(
        isSharedBsr,
        sharedBsrParentAsin,
        cellProps,
        'revenue'
      )
    },
    sortable: true,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Revenue1P,
    defaultSelection: true,
    type: 'number'
  },
  {
    index: 3,
    position: 3,
    accessor: 'revenue3p',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.revenue3p',
            '3P Revenue'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.revenue3p,
    Cell: ({
      row: {
        original: {
          revenue3p: value,
          revenue3pTrend: trend,
          isSharedBsr,
          sharedBsrParentAsin,
          asin
        }
      }
    }) => {
      const cellProps = {
        value,
        trend,
        currencyCode,
        isAsin: !!asin,
        hasTrendData
      }
      return productTrendOrEmptyChildCell(
        isSharedBsr,
        sharedBsrParentAsin,
        cellProps,
        'revenue'
      )
    },
    sortable: true,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.Revenue3P,
    defaultSelection: true,
    type: 'number'
  },
  {
    index: 4,
    position: 4,
    accessor: 'unitSales',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.unitSales',
            'Unit Sales'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.unitSales,
    Cell: ({
      row: {
        original: {
          unitSales: value,
          unitSalesTrend: trend,
          isSharedBsr,
          sharedBsrParentAsin,
          asin
        }
      }
    }) => {
      const cellProps = {
        value,
        trend,
        currencyCode: undefined,
        isAsin: !!asin,
        decimalPoints: unitSalesDecimalPoints,
        hasTrendData
      }
      return productTrendOrEmptyChildCell(
        isSharedBsr,
        sharedBsrParentAsin,
        cellProps,
        'unit sales'
      )
    },
    sortable: true,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.UnitSales,
    defaultSelection: true,
    type: 'number'
  },
  {
    index: 6,
    position: 6,
    accessor: 'unitSales1p',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.unitSales1p',
            '1P Unit Sales'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.unitSales1p,
    Cell: ({
      row: {
        original: {
          unitSales1p: value,
          unitSales1pTrend: trend,
          isSharedBsr,
          sharedBsrParentAsin,
          asin
        }
      }
    }) => {
      const cellProps = {
        value,
        trend,
        currencyCode: undefined,
        isAsin: !!asin,
        decimalPoints: unitSalesDecimalPoints,
        hasTrendData
      }
      return productTrendOrEmptyChildCell(
        isSharedBsr,
        sharedBsrParentAsin,
        cellProps,
        'unit sales'
      )
    },
    sortable: true,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.UnitSales1P,
    defaultSelection: true,
    type: 'number'
  },
  {
    index: 7,
    position: 7,
    accessor: 'unitSales3p',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.brandTrendsTable.labels.unitSales3p',
            '3P Unit Sales'
          )
        }
      </Translation>
    ),
    tooltipContent: CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS.unitSales3p,
    Cell: ({
      row: {
        original: {
          unitSales3p: value,
          unitSales3pTrend: trend,
          isSharedBsr,
          sharedBsrParentAsin,
          asin
        }
      }
    }) => {
      const cellProps = {
        value,
        trend,
        currencyCode: undefined,
        decimalPoints: unitSalesDecimalPoints,
        isAsin: !!asin,
        hasTrendData
      }
      return productTrendOrEmptyChildCell(
        isSharedBsr,
        sharedBsrParentAsin,
        cellProps,
        'unit sales'
      )
    },
    sortable: true,
    dataId: SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS.SortColumns.UnitSales3P,
    defaultSelection: true,
    type: 'number'
  },
  ...(additionalColumns
    ? BRANDS_TRENDS_TABLE_ADDITIONAL_COLUMNS(
        currencyCode,
        usingAdminMegaQueries,
        isAdminOrImpersonating
      )
    : [])
]

export const LOCAL_STORAGE_KEYS = {
  SHARE_AND_TRENDS_TABLE_BRANDS: 'SHARE_AND_TRENDS_TABLE_BRANDS',
  SHARE_AND_TRENDS_TABLE_PRODUCTS: 'SHARE_AND_TRENDS_TABLE_PRODUCTS',
  SHARE_AND_TRENDS_TABLE_VARIANTS: 'SHARE_AND_TRENDS_TABLE_VARIANTS'
}

export const BRANDS_TABLE_PAGE_SIZE = 25
export const BRANDS_TABLE_PRODUCTS_PAGE_SIZE = 5
export const BRANDS_TABLE_PRODUCTS_LOAD_MORE_PAGE_SIZE = 5

export const SELLERS_TABLE_PAGE_SIZE = 25
export const SELLERS_TABLE_PRODUCTS_LOAD_MORE_PAGE_SIZE = 5

export const BRANDS_MAX_DROPDOWN = 10
export const BRANDS_DROPDOWN_DEFAULT_ORDER = 'revenue desc'

export const DEFAULT_SORT = 'name'
export const DEFAULT_DIRECTION = 'asc'

export const TREE_MAP_MAPPING = trendsType => {
  const mapping = {
    asins: {
      valueTrend: 'asinCountTrend',
      marketShare: 'asinCountShare',
      marketShareTrend: 'asinCountShareTrend',
      size: 'asinCount'
    },
    unitSales: {
      valueTrend: 'unitSalesTrend',
      marketShare: 'unitSalesShare',
      marketShareTrend: 'unitSalesShareTrend',
      size: 'unitSales'
    },
    revenue: {
      valueTrend: 'revenueTrend',
      marketShare: 'share',
      marketShareTrend: 'shareTrend',
      size: 'revenue'
    },
    revenue1p: {
      valueTrend: 'revenue1pTrend',
      marketShare: 'revenue1pShare',
      marketShareTrend: 'revenue1pShareTrend',
      size: 'revenue1p'
    },
    revenue3p: {
      valueTrend: 'revenue3pTrend',
      marketShare: 'revenue3pShare',
      marketShareTrend: 'revenue3pShareTrend',
      size: 'revenue3p'
    }
  }

  return mapping[trendsType]
}

export const NEGATIVE_TREE_MAP_GRAPH_COLORS = [
  '#ecb898', // lightest
  '#f49859',
  COLORS.primary // darkest
]

export const POSITIVE_TREE_MAP_GRAPH_COLORS = [
  '#b7ded3', // lightest
  '#78cab6',
  '#0fb69a' // darkest
]

export const ZERO_TREND_TREE_MAP_GRAPH_COLOR = COLORS.grey200

export const TREE_MAP_GRAPH_COLORS = [
  ...[...NEGATIVE_TREE_MAP_GRAPH_COLORS].reverse(), // to get the order right for the scale
  ZERO_TREND_TREE_MAP_GRAPH_COLOR,
  ...POSITIVE_TREE_MAP_GRAPH_COLORS
]

export const SPLIT_REVENUE_DATE = moment('5-21-2020', 'MM-DD-YYYY')

export const EXCEL_EXPORTS_TABLE_COLUMNS = [
  {
    index: 1,
    position: 1,
    Header: (
      <Translation ns="market_insights">
        {t =>
          t('market_insights:ShareAndTrends.ExcelExportsTable.user', 'User')
        }
      </Translation>
    ),
    accessor: 'user_id',
    Cell: item => (
      <ExcelExportsTextCell text={item?.row?.original?.user_info?.email} />
    ),
    defaultSelection: true,
    sortable: true,
    width: 350
  },
  {
    index: 1,
    position: 1,
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.ExcelExportsTable.reportType',
            'Report Type'
          )
        }
      </Translation>
    ),
    accessor: 'export_type',
    Cell: ({ row }) => {
      const { t } = useTranslation('market_insights')
      const { export_type } = row.original

      const source = export_type.toLowerCase().includes('3p')
        ? `${t(
            'market_insights:ExportDropdown.3pSellersData',
            '3P Sellers Export'
          )}`
        : t(
            'market_insights:ExportDropdown.segmentData',
            'Products & Brands Export'
          )

      const text = `${source}`

      return ExcelExportsTextCell({ text })
    },
    defaultSelection: true,
    sortable: true
  },
  {
    index: 3,
    position: 3,
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.ExcelExportsTable.dateRange',
            'Date Range'
          )
        }
      </Translation>
    ),
    accessor: 'range',
    defaultSelection: true,
    sortable: true
  },
  {
    index: 4,
    position: 4,
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.ExcelExportsTable.segmentState',
            'Segment State'
          )
        }
      </Translation>
    ),
    accessor: 'segment_state',
    defaultSelection: false,
    sortable: true
  },
  {
    index: 5,
    position: 5,
    Header: () => {
      const { t } = useTranslation('market_insights')
      return (
        <>
          <div>
            {t(
              'market_insights:ShareAndTrends.ExcelExportsTable.requestedDate',
              'Created Date'
            )}
          </div>
          <QuestionTooltip
            content={t(
              'market_insights:ShareAndTrends.ExcelExportsTable.tooltip.createDate',
              'The data in the UI may have updated since last created date. '
            )}
            margin="0 0 0 4px"
          />
        </>
      )
    },
    accessor: 'created_at',
    defaultSelection: true,
    sortable: true
  },
  {
    index: 6,
    position: 6,
    Header: (
      <Translation ns="market_insights">
        {t => (
          <LinkTooltip>
            {t('market_insights:ShareAndTrends.ExcelExportsTable.link', 'Link')}
          </LinkTooltip>
        )}
      </Translation>
    ),
    accessor: 'url',
    defaultSelection: true,
    Cell: ExcelExportsDownloadCell
  }
]

export const SELLERS_TABLE_COLUMNS = (currencyCode = null, onAsinClick) => [
  {
    index: 0,
    position: 0,
    accessor: 'seller',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.sellersTable.labels.seller',
            'Seller'
          )
        }
      </Translation>
    ),
    subHeader: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.sellersTable.labels.productInfo',
            'Product Info'
          )
        }
      </Translation>
    ),
    Cell: ({ row }) => {
      const is3PSeller = true
      return SubRowCellTitleCell({ row, onAsinClick, is3PSeller })
    },
    sortable: true,
    width: 437,
    defaultSelection: true
  },
  {
    index: 1,
    position: 1,
    accessor: 'revenueShare',
    Header: (
      <Translation ns="market_insights">
        {t => (
          <RevenueHeaderTooltip>
            {t(
              'market_insights:ShareAndTrends.sellersTable.labels.revenue',
              'Revenue'
            )}
          </RevenueHeaderTooltip>
        )}
      </Translation>
    ),
    subHeader: (
      <Translation ns="market_insights">
        {t => (
          <RevenueSubheaderTooltip>
            <span>
              {t(
                'market_insights:ShareAndTrends.sellersTable.labels.share',
                'Share'
              )}
            </span>
          </RevenueSubheaderTooltip>
        )}
      </Translation>
    ),
    Cell: ({ row }) => {
      const { revenue: value, revenueShare: share } = row.original

      if (row.depth === 2) {
        return EmptyChildCell('revenue')
      }

      return SellerShareCell({ value, share, currencyCode })
    },
    sortable: true,
    defaultSelection: true,
    type: 'number'
  },
  {
    index: 2,
    position: 2,
    accessor: 'unitSalesShare',
    Header: (
      <Translation ns="market_insights">
        {t => (
          <UnitSalesHeaderTooltip>
            {t(
              'market_insights:ShareAndTrends.brandTrendsTable.labels.unitSales',
              'Unit Sales'
            )}
          </UnitSalesHeaderTooltip>
        )}
      </Translation>
    ),
    subHeader: (
      <Translation ns="market_insights">
        {t => (
          <UnitSalesSubheaderTooltip>
            {t(
              'market_insights:ShareAndTrends.sellersTable.labels.share',
              'Share'
            )}
          </UnitSalesSubheaderTooltip>
        )}
      </Translation>
    ),
    Cell: ({ row }) => {
      const { unitSales: value, unitSalesShare: share } = row.original

      if (row.depth === 2) {
        return EmptyChildCell('unit sales')
      }

      return SellerShareCell({ value, share })
    },
    sortable: true,
    defaultSelection: true,
    type: 'number'
  },
  {
    index: 3,
    position: 3,
    accessor: 'buyBoxWinnerShare',
    Header: (
      <Translation ns="market_insights">
        {t =>
          t(
            'market_insights:ShareAndTrends.sellersTable.labels.buyBoxWinnerShare',
            'Buy Box Win %'
          )
        }
      </Translation>
    ),
    Cell: ({ row }) => {
      const { buyBoxWinnerShare } = row.original

      if (row.depth === 2) {
        return EmptyChildCell('buy box win')
      }

      const value = BuyboxWinPercentageRowTooltip({
        depth: row.depth,
        children:
          buyBoxWinnerShare != null ? `${buyBoxWinnerShare.toFixed(2)}%` : ''
      })

      return StringCell({ value })
    },
    sortable: true,
    defaultSelection: true,
    type: 'number'
  }
]

export const MARKETPLACES = {
  us: {
    id: 'ATVPDKIKX0DER',
    domainName: 'www.amazon.com'
  },
  ca: {
    id: 'A2EUQ1WTGCTBG2',
    domainName: 'www.amazon.ca'
  },
  mx: {
    id: 'A1AM78C64UM0Y8',
    domainName: 'www.amazon.mx'
  },
  br: {
    id: 'A2Q3Y263D00KWC',
    domainName: 'www.amazon.br'
  },
  uk: {
    id: 'A1F83G8C2ARO7P',
    domainName: 'www.amazon.co.uk'
  },
  de: {
    id: 'A1PA6795UKMFR9',
    domainName: 'www.amazon.de'
  },
  es: {
    id: 'A1RKKUPIHCS9HS',
    domainName: 'www.amazon.es'
  },
  fr: {
    id: 'A13V1IB3VIYZZH',
    domainName: 'www.amazon.fr'
  },
  be: {
    id: 'AMEN7PMS3EDWL',
    domainName: 'www.amazon.com.be'
  },
  it: {
    id: 'APJ6JRA9NG5V4',
    domainName: 'www.amazon.it'
  },
  nl: {
    id: 'A1805IZSGTT6HS',
    domainName: 'www.amazon.nl'
  },
  pl: {
    id: 'A1C3SOZRARQ6R3',
    domainName: 'www.amazon.pl'
  },
  tr: {
    id: 'A33AVAJ2PDY3EV',
    domainName: 'www.amazon.tr'
  },
  ae: {
    id: 'A2VIGQ35RCS4UG',
    domainName: 'www.amazon.ae'
  },
  in: {
    id: 'A21TJRUUN4KGV',
    domainName: 'www.amazon.in'
  },
  sa: {
    id: 'A17E79C6D8DWNP',
    domainName: 'www.amazon.sa'
  },
  eg: {
    id: 'ARBP9OOSHTCHU',
    domainName: 'egypt.souq.com'
  },
  jp: {
    id: 'A1VC38T7YXB528',
    domainName: 'www.amazon.co.jp'
  }
}
